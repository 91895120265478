// COMPONENTS
import React from 'react'
import Page from 'templates/Page'
// CONTENT
import content from 'content/404.yml'

// Error page
const ErrorPage = () => {
  return <Page content={content} signup={false} error />
}

export default ErrorPage
